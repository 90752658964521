.header_fixed {
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 5;
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;

    .wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 0 40px;

        h2 {
            color: var(--color-primary);
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.5px;
        }

        .not_found {
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 2px 5px 11.2px 0px rgba(140, 140, 140, 0.11);
            padding: 20px 0;
            
            p {
                color: var(--color-primary);
            }

            .p_simple {
                margin-bottom: 30px;
            }

            .p_multiple {
                line-height: 32px;
            }
        }

        // Border rounder: https://unused-css.com/blog/css-rounded-table-corners/
        table {
            background: var(--color-white);
            border-spacing: 0;
            border-collapse: separate;
            width: 100%; // 1358px;
            overflow-y: scroll;

            thead {
                background-color: var(--color-table-head);
                color: var(--color-primary);

                th {
                    font-size: 16px;
                    padding: 15px 4px;
                }
            }

            tbody {
                color: var(--color-primary);
                box-shadow: 2px 5px 11.2px 0px rgba(140, 140, 140, 0.11);

                td {
                    font-size: 14px;
                    padding: 12px 4px;

                    .photo {
                        border-radius: 50px;
                        height: 50px;
                        width: 50px;
                        object-fit: cover;
                    }

                    .photo_padding {
                        padding-right: 30px;
                    }

                    .name {
                        font-weight: 600;
                        font-style: normal;
                    }

                    .review_container {
                        display: flex;
                        justify-content: center;
                        gap: 5px;
                    }
                }

                tr:hover {
                    border-radius: 20px;
                    background: var(--color-table-hover);
                    cursor: pointer;
                }
            }

            // Border rounder: https://stackoverflow.com/questions/9873936/how-to-create-rounded-corners-on-table-head-only
            .first_child_border{
                border-radius:10px 0 0 10px;
                width: 150px;
            }

            .last_child_border{
                border-radius:0 10px 10px 0;
                width: 50px;
            }
        }

        .backbutton {
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            width: max-content;
            color: var(--color-back-button);
            padding-top: 30px;

            span {
                color: var(--color-back-button);
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
            }
        }

        .resume {
            display: flex;
            flex-direction: row;

            .data {
                background-image: url(../../../assets/img/background_resume.png);
                background-size: 100% 100%;
                color: var(--color-white);
                display: flex;
                flex-direction: column;
                gap: 30px;
                justify-content: center;
                border: 1px solid;
                border-radius: 20px;
                margin-right: 25px;
                width: 25%;
                height: max-content;
                padding: 34px;
                text-align: center;

                .image {
                    width: 220px;
                    height: 220px;
                    margin: 0 auto;
                    position: relative;

                    .photo {
                        border: 4px solid var(--color-secondary);
                        border-radius: 50%;
                        height: 206px;
                        width: 206px;
                        object-fit: cover;
                    }

                    .button {
                        border-radius: 7px;
                        position: absolute;
                        top: 180px;
                        left: 30px;
                        right: 30px;
                        z-index: 2;

                        .container {
                            display: flex;
                            height: 35px;
                            text-align: center;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;

                            .title {
                                background-color: var(--color-secondary);
                                display: grid;
                                align-items: center;
                                width: 20%;
                                border-top-left-radius: 7px;
                                border-bottom-left-radius: 7px;
                            }

                            .download {
                                background-color: var(--color-blue);
                                display: grid;
                                align-items: center;
                                cursor: pointer;
                                width: 80%;
                                border-top-right-radius: 7px;
                                border-bottom-right-radius: 7px;

                                &:hover {
                                    background-color: var(--color-secondary);
                                }
                            }
                        }

                    }
                }

                .name {
                    font-size: 18px;
                    font-weight: 700;
                }

                .position {
                    font-size: 18px;
                    font-weight: 400;
                }

                .feedback {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .stars{
                        display: flex;
                        justify-content: center;
                        gap: 4px;

                        .selected {
                            filter: invert(46%) sepia(73%) saturate(1224%) hue-rotate(340deg) brightness(103%) contrast(94%);
                        }
                    }
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    gap: 23px;

                    .container {
                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        gap: 19px;
                        padding: 0 18px;

                        .title {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: left;
                            gap: 4px;

                            label {
                                font-weight: 600;
                            }

                            span {
                                text-align: justify;
                                padding-right: 20px;
                            }
                        }
                    }

                    .separator {
                        background-color: #284C9A;
                        width: 100%;
                        height: 1px;
                    }
                }
            }

            .detail {
                display: flex;
                flex-direction: column;
                gap: 24px;
                margin-left: 25px;
                width: 75%;

                .menu {
                    background-color: #EFF3F8;
                    border-radius: 40px;
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    padding: 4px;
                    justify-content: center;

                    span {
                        font-style: normal;
                        font-weight: 600;
                        padding: 10px 24px;
                        text-align: center;
                        width: 20%;
                    }

                    .selected {
                        background: var(--color-white);
                        border: 2px solid var(--color-blue);
                        border-radius: 40px;
                        padding: 10px 24px;
                    }
                }

                .sections {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    .title {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: left;
                        gap: 10px;

                        .title_bar {
                            background-color: var(--color-secondary);
                            border-radius: 99px;
                            width: 4px;
                            height: 44px;
                        }

                        h2 {
                            color: var(--color-blue);
                        }

                        .title_rewiew {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            text-align: left;
                            width: 100%;
                        }
                    }

                    .p_title {
                        font-size: 16px;
                        font-weight: 600;
                    }

                    .section {
                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        p {
                            text-align: justify;
                        }
    
                        .content {
                            padding: 0 15px;
                            color: #0C1137;

                            .pharagrap {
                                display: flex;
                                flex-direction: column;
                                gap: 16px;

                                .e_title {
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: var(--color-primary);
                                }

                                .inline {
                                    display: flex;
                                    gap: 20px;
                                    padding: 16px 0;
                                    flex-wrap: wrap;

                                    .e_item {
                                        background: var(--color-btn-feedback-new);
                                        border-radius: 10px;
                                        display: flex;
                                        padding: 4px 15px;
                                        align-items: flex-start;
                                        font-size: 14px;
                                        gap: 10px;
                                        color: var(--color-white);
                                        width: max-content;
                                    }

                                    .no_info {
                                        font-weight: 400;
                                        color: black;
                                    }
                                }
                            }

                            .courses {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;
                                padding: 12px 0;

                                .title {
                                    font-size: 18px;
                                    font-weight: 600;
                                }
                            }

                            .work_experience {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                p {
                                    overflow-wrap: anywhere;
                                }
                            }

                            .review {
                                display: flex;
                                flex-direction: column;
                                gap: 15px;

                                .feedback {
                                    display: flex;
                                    flex-direction: row;
                                    gap: 20px;
                                }

                                .comments {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 6px;
                                }
                            }
                        }
                    }

                    .section_review {
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                        width: 100%;
                    }

                    .stars {
                        display: flex;
                        justify-content: center;
                        gap: 4px;

                        .selected {
                            filter: invert(46%) sepia(73%) saturate(1224%) hue-rotate(340deg) brightness(103%) contrast(94%);
                        }
                    }

                    .review_card {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 0 10px;

                        .arrow_view {
                            width: 33px;
                            height: 33px;
                            padding-left: 29px;
                        }
                    }

                    .review_card:hover {
                        border-radius: 10px;
                        background: var(--color-table-hover);
                    }
                }

                .not_found {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 45px;
                    padding: 100px;

                    div {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        text-align: center;
                        color: var(--color-primary);

                        .h2 {
                            font-size: 24px;
                            font-weight: 700;
                        }

                        .h3 {
                            font-size: 20px;
                            font-weight: 400;
                        }
                    }
                }

                .feedback_title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-top: 18px;
                    padding-bottom: 18px;
                    padding-right: 18px;

                    .button {
                        border-radius: 10px;
                        cursor: pointer;
                        background: var(--color-btn-feedback-new);
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                        font-size: 16px;
                        font-weight: 600;
                        color: var(--color-white);
                        padding: 14px;
                    }

                    .button:hover {
                        background: var(--color-blue);
                    }
                }
            }
        }
    }
}

.error_boundary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    gap: 8px;

    .font {
        color: #3F5073;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
    }

    .info {
        font-weight: 400;
    }

    .email {
        color: #109AE8;
    }
}

.textleft {
    text-align: left;
}

.textcenter {
    text-align: center;
}

.textright {
    text-align: right;
}

.padding_x_10 {
    padding: 0 10px;
}

.padding_x_20 {
    padding: 0 20px;
}

.padding_y_16 {
    padding: 16px 0;
}

.padding_left_30 {
    padding-left: 30px;
}

.width_90 {
    width: 90%;
}

.width_40 {
    width: 40%;
}
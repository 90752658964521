:root {
    --color-background: #FCFDFE;
    --color-primary: #3F5073;
    --color-primary-dark: #091026;
    --color-border-gray: #8FAACC;
    --color-secondary: #F86F35;
    --color-white: #FFF;
    --color-gray: #F0F3F7;
    --color-table-head: #EFF3F8;
    --color-back-button: #001330;
    --color-blue: #0111A2;
    --color-blue2: #0C1137;
    --color-blue3: #3B43FB;
    --color-blue-ligth: #9EA8FF;
    --color-table-hover: rgba(248, 111, 53, 0.20);
    --color-btn-feedback-new: #00194F;
    --color-error: #FF2E3A;
    --color-scrollbar: #FA7D61;
    --color-start-title: #171E40;
    --color-modal-background: rgba(0, 0, 0, 0.70);
    --color-black-blue: #050D2A;
    --color-notification-success: #0F9043;
    --color-notification-warning: #E01B1B;
}
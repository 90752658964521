.paginator {
    display: flex;
    justify-content: center;
    gap: 15px;

    .label {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
    }

    .button {
        display: flex;
        border-radius: 5px;
        background: var(--color-white);
        height: 40px;
        width: 40px;
    }

    .button_disabled {
        border: 1px solid var(--color-gray);
    }

    .button_enabled {
        border: 1.258px solid var(--color-border-gray);
    }
}
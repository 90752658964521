.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-primary-dark);
    height: 100px;
    padding: 0 39px;

    .user_info {
        display: flex;
        align-items: center;
        gap: 11px;
        color: var(--color-white);
        font-size: 20px;
        font-weight: 600;

        &_separator {
            border-radius: 2px;
            background: var(--color-secondary);
            width: 3px;
            height: 33px;
        }
    }
}

.header_talent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-blue2);
    height: 140px;
    padding: 0 80px;

    .talent_info {
        align-items: center;
        color: var(--color-white);
        background-image: url(../../../assets/icons/logo_profile.svg);
        background-size: contain;
        background-position: left;
        background-repeat: no-repeat;
        display: flex;
        gap: 25px;
        height: 140px;
        padding-left: 45px;
        width: 50%;

        .user_img {
            align-items: center;
            justify-content: center;
            display: flex;
            
            .photo {
                border: 4px solid var(--color-secondary);
                border-radius: 50%;
                height: 109px;
                width: 109px;
                object-fit: cover;
            }
        }

        &_name {
            color: var(--color-white);
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
        }

        &_position {
            color: var(--color-white);
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
        }
    }

    .talent_info_logo {
        display: flex;
        align-items: center;
        gap: 24px;
        color: var(--color-white);
        font-weight: 600px;

        &_separator {
            border-radius: 2px;
            background: var(--color-secondary);
            width: 3px;
            height: 74px;
        }

        &_title {
            color: var(--color-white);
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            text-transform: uppercase;
        }

        img {
            width: 123px;
            height: 27px;
        }
    }
}
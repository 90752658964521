.full_container {
    display: flex;
    flex-direction: column;

    label {
        padding-top: 12px;
    }

    .error_message {
        color: var(--color-error);
    }
}

.container {
    display: flex;

    .input {
        background-color: var(--color-white);
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        border-top: 1px solid var(--color-primary);
        border-bottom: 1px solid var(--color-primary);
        border-left: 1px solid var(--color-primary);
        padding: 0 16px;
        width: 75%;

        input {
            outline: none;
            border: none;
            font-size: 16px;
            height: 48px;
            width: 100%;
        }
    }

    .button {
        align-items: center;
        background-color: var(--color-gray);
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        border-top: 1px solid var(--color-primary);
        border-bottom: 1px solid var(--color-primary);
        border-right: 1px solid var(--color-primary);
        cursor: pointer;
        display: flex;
        font-size: 0 16px;
        gap: 16px;
        justify-content: center;
        width: 25%;
        padding: 0 16px;

        svg {
            height: 21px;
            width: 21px;

            path {
                fill: black;
            }
        }
    }

    .button:hover {
        background-color: var(--color-blue);
        color: var(--color-white);
        cursor: pointer;

        svg {
            
            path {
                fill: var(--color-white);
            }
        }
    }
}
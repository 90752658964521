.container {
    display: flex;
    border-radius: 12px;
    border: 1px solid var(--color-primary);
    background: linear-gradient(to right,  var(--color-white) 0%,var(--color-white) 75%,var(--color-gray) 75%,var(--color-gray) 100%);
    padding: 0 16px; 

    input {
        outline: none;
        border: none;
        font-size: 16px;
        height: 48px;
        padding-right: 16px;
        width: 75%;
    }

    .button {
        align-items: center;
        background-color: var(--color-gray);
        cursor: pointer;
        display: flex;
        font-size: 16px;
        gap: 16px;
        justify-content: center;
        width: 25%;

        img {
            height: 24px;
            width: 24px;
        }
    }
}
.modal_wrapper {
    &.modal_active {
        padding-right: 18px;
        height: 100vh;
        overflow: hidden!important;
    }

    .modal_base {
        background-color: var(--color-modal-background);
        display: flex;
        flex-direction: column;
        position: fixed;
        align-items: center;
        min-width: 100%;
        min-height: 100%;
        height: 100%;
        width: 100%;
        z-index: 3;
    
        .stop_propagation {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
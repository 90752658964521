.container {
    width: 100%;
    padding-top: 57px;

    .error_message {
        color: var(--color-error);
    }

    .label {
        color: var(--color-blue);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
    }

    .sublabel {
        color: var(--color-blue2);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        padding-top: 43px;
    }

    .under_text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 4px;
    }

    .textarea {
        display: flex;
        flex-direction: column;
        gap: 1px;
        padding: 16px 8px;
        margin-top: 16px;
        border-radius: 10px;
        border: 1px solid var(--color-back-button);
        background: #FFF;

        &_scroll {
            display: flex;
            flex-direction: column;
            gap: 1px;

            textarea {
                color: var(--color-blue2);
                font-family: "Open Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                resize: none;
                height: 180px;
                border: none;
                outline: none;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
            }
    
            ::-webkit-scrollbar-track {
                border-radius: 50px;
            }
    
            ::-webkit-scrollbar {
                width: 8px;
            }
    
            ::-webkit-scrollbar-thumb {
                border-radius: 50px;
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                background-color: var(--color-scrollbar);
            }

        }
    }
}
.loading_container {
    position: absolute;
    top: 30%;

    &_content {
        display: flex;
        flex-direction: column;
        align-items: center;

        label {
            color: var(--color-white);
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            gap: 5px;
        }
    }
}

.no_scroll {
    overflow: hidden;
 }
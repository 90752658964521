.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 107px;

    h2 {
        color: var(--color-primary);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.5px;
    }

    .backbutton {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        width: max-content;
        color: var(--color-back-button);
        padding-top: 30px;

        span {
            color: var(--color-blue3);
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
        }

        path {
            fill: var(--color-blue3);
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        padding: 40px 32px;
        align-items: center;
        gap: 24px;
        border-radius: 20px;
        background: var(--color-white);
        box-shadow: 0px 0px 12px 0px rgba(1, 17, 162, 0.30);

        .title {
            color: var(--color-blue);
            text-align: center;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            text-transform: uppercase;
            width: 100%;
        }

        .separator {
            background-color: var(--color-blue-ligth);
            box-shadow: 0px 0px 1px 0px rgba(1, 17, 162, 0.30);
            width: 95%;
            height: 1px;
        }

        .paragraph {
            display: flex;
            flex-direction: column;
            width: 100%;

            p {
                margin-bottom: 24px;
            }
        }

        .skill_sections {
            display: flex;
            flex-direction: column;
            gap: 40px;
            width: 100%;

            &_item {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 100%;
    
                &_title {
                    color: var(--color-secondary);
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    text-align: left;
                    width: 100%;
                }
    
                .new_button {
                    padding-top: 20px;
                }

                &_pills {
                    display: flex;
                    gap: 16px;
                    padding: 16px 0;
                    flex-wrap: wrap;

                    .pill {
                        display: inline-flex;
                        border: 2px solid var(--color-blue);
                        border-radius: 40px;
                        display: flex;
                        padding: 10px 24px;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        width: max-content;

                        svg {
                            border: 2px solid var(--color-white);
                            border-radius: 50%;
                            padding: 2px;
                        }
                    }

                    .pill:hover {
                        background: var(--color-blue);
                        color: var(--color-white);
                        cursor: pointer;

                        svg {
                            border: 2px solid var(--color-white);
                            border-radius: 50%;
                            padding: 2px;
                        }

                        path {
                            stroke: var(--color-white);
                            border: 2px solid var(--color-white);
                        }
                    }

                    .unselected {
                        background: var(--color-white);
                        color: var(--color-blue2);

                        svg {
                            border: 2px solid black;
                            border-radius: 50%;
                            padding: 2px;
                        }

                        path {
                            stroke: black;
                            border: 2px solid black;
                        }
                    }

                    .selected {
                        background: var(--color-blue);
                        color: var(--color-white);

                        svg {
                            border: 2px solid var(--color-white);
                            border-radius: 50%;
                            padding: 2px;
                        }
                    }
                }
            }
        }

        .star_section {
            display: flex;
            flex-direction: column;
            gap: 30px;
            padding-bottom: 36px;

            .title {
                color: var(--color-start-title);
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                text-transform: none;
            }
    
            .subtitle {
                color: var(--color-blue2);
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
            }

            .feedback {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 16px;
                padding-left: 220px;
                padding-right: 220px;

                .stars{
                    display: flex;
                    justify-content: center;
                    gap: 4px;

                    .selected {
                        filter: invert(46%) sepia(73%) saturate(1224%) hue-rotate(340deg) brightness(103%) contrast(94%);
                    }
                }

                .description {
                    color: var(--color-back-button);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    text-align: center;
                }

                .instruction {
                    color: var(--color-back-button);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    text-align: center;
                }
            }
        }
    }

    .button_container {
        display: flex;
        width: 100%;
        justify-content: center;
        padding-bottom: 80px;
        
        .button {
            border-radius: 10px;
            cursor: pointer;
            background: var(--color-btn-feedback-new);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            font-size: 16px;
            font-weight: 600;
            color: var(--color-white);
            padding: 16px;
            width: 30%;
            text-align: center;
        }

        .button:hover {
            background: var(--color-blue);
        }
    }
}

.width_40 {
    width: 40%;
}

.modal_cancel_evaluation {
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid black;
    background: var(--color-white);
    position: absolute;
    top: 25%;
    left: 25%;
    padding: 80px 100px;
    
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .question {
            color: black;
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
        }

        .instruction {
            color: black;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
        }

        .buttons {
            display: flex;
            gap: 24px;
    
            .button_cancel {
                cursor: pointer;
                display: flex;
                padding: 16px;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                border: 1px solid var(--color-blue2);
                background: var(--color-white);
                color: var(--color-blue2);
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
            }

            .button_confirm {
                cursor: pointer;
                display: flex;
                padding: 16px;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                border: 1px solid var(--color-blue2);
                background: var(--color-black-blue);
                color: var(--color-white);
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
            }
        }
    }
}